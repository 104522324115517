<template>
    <div>
        <portal to="appBarNavigationTitle">
            {{ headline }}
        </portal>
        <portal to="appBarNavigationAction">
            <BaseBackButton />
        </portal>
        <v-container
            fluid
        >
            <v-row align="start" justify="start" class="pt-6">
                <v-col cols="12" md="6">
                    <v-card class="v-card--material" color="white">
                        <div class="d-flex--v2 grow flex-wrap">
                            <v-sheet
                                color="indigo"
                                max-height="90"
                                width="auto"
                                class="text-start v-card--material__heading mb-n6 elevation-6 pa-7"
                            >
                                <v-icon large color="white">
                                    mdi-history
                                </v-icon>
                            </v-sheet>
                            <div class="ml-6">
                                <v-card-title>
                                    <span
                                        class="text-h6 font-weight-light"
                                        v-t="'dashboard.activityStream.headline'"
                                    />
                                </v-card-title>
                            </div>
                        </div>
                        <v-card-text>
                            <span
                                v-if="
                                    !$wait.is('fetch activity stream') &&
                                        !activityToday &&
                                        !activityYesterday &&
                                        !activityLastWeek &&
                                        !activityElse
                                "
                            >
                                {{ $t('dashboard.activityStream.noStream') }}
                            </span>
                            <v-skeleton-loader
                                v-if="$wait.is('fetch activity stream') "
                                :type="'sentences'"
                                tile
                                class="mx-auto pt-3"
                                height="54"
                            ></v-skeleton-loader>
                            <v-timeline
                                v-else-if="(activityToday ||
                                            activityYesterday ||
                                            activityLastWeek ||
                                            activityElse)
                                "
                                dense
                                allign-top
                                class="ml-2"
                            >
                                <v-timeline-item
                                    v-if="activityToday"
                                    color="indigo"
                                    small
                                    fill-dot
                                >
                                    <ActivityStreamGroup
                                        :group-name="
                                            $t(
                                                'dashboard.dateGroup.' +
                                                    activityToday.group
                                            )
                                        "
                                        :items="activityToday.items"
                                    />
                                </v-timeline-item>
                                <v-timeline-item
                                    v-if="activityYesterday"
                                    color="indigo"
                                    small
                                    fill-dot
                                >
                                    <ActivityStreamGroup
                                        :group-name="
                                            $t(
                                                'dashboard.dateGroup.' +
                                                    activityYesterday.group
                                            )
                                        "
                                        :items="activityYesterday.items"
                                    />
                                </v-timeline-item>
                                <v-timeline-item
                                    v-if="activityLastWeek"
                                    color="indigo"
                                    small
                                    fill-dot
                                >
                                    <ActivityStreamGroup
                                        :group-name="
                                            $t(
                                                'dashboard.dateGroup.' +
                                                    activityLastWeek.group
                                            )
                                        "
                                        :items="activityLastWeek.items"
                                    />
                                </v-timeline-item>
                                <template v-if="activityElse">
                                    <v-timeline-item
                                        v-for="activityGroup in activityElse"
                                        :key="activityGroup.group"
                                        color="indigo"
                                        small
                                        fill-dot
                                    >
                                        <ActivityStreamGroup
                                            :group-name="activityGroup.group"
                                            :items="activityGroup.items"
                                        />
                                    </v-timeline-item>
                                </template>
                            </v-timeline>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import titleMixin from '@/mixins/titleMixin';
import ActivityStreamGroup from '@/components/activityStream/ActivityStreamGroup.vue';

export default {
    name: 'ActivityStream',
    components: {
        ActivityStreamGroup,
    },
    mixins: [titleMixin],
    title() {
        return this.headline;
    },
    i18n: {
        messages: {
            en: require('@/locales/Dashboard/en.json'),
            de: require('@/locales/Dashboard/de.json'),
        },
    },
    data() {
        return {};
    },
    computed: {
        ...mapGetters({
            items: 'activityStream/getGroupedItems',
        }),
        headline() {
            return this.$t('dashboard.activityStream.headline');
        },
        activityToday() {
            const data = this.items.find(x => x.group === 'today');
            if (!data) {
                return null;
            }
            return data.items && data.items.length ? data : null;
        },
        activityYesterday() {
            const data = this.items.find(x => x.group === 'yesterday');
            if (!data) {
                return null;
            }
            return data.items && data.items.length ? data : null;
        },
        activityLastWeek() {
            const data = this.items.find(x => x.group === 'lastWeek');
            if (!data) {
                return null;
            }
            return data.items && data.items.length ? data : null;
        },
        activityElse() {
            const arrData = this.items.filter(
                x =>
                    x.group !== 'today' &&
                    x.group !== 'yesterday' &&
                    x.group !== 'lastWeek'
            );
            if (!arrData || !arrData.length) {
                return null;
            }
            return arrData;
        },
    },
    methods: {
        ...mapActions({
            fetchActivityStream: 'activityStream/fetchItems',
        }),
        async fetchData() {
            this.$wait.start('fetch activity stream');
            await this.fetchActivityStream();
            this.$wait.end('fetch activity stream');
        },
    },
    created() {
        this.fetchData();
    },
};
</script>
