<template>
    <v-card color="transparent">
        <v-card-title class="text-subtitle-1 font-weight-thin">{{
            groupName
        }}</v-card-title>
        <div>
            <template v-for="(item, i) in items">
                <v-card-text class="white text--primary" :key="i">
                    <div class="pl-0">
                        <span>{{ item.firstName }} {{ item.lastName }}</span
                        >&nbsp;
                        <span>{{
                            $t('dashboard.activityStream.types.' + item.action)
                        }}</span
                        >&nbsp;
                        <span
                            v-if="item.processingActivity"
                            class="font-weight-bold undecorated-link"
                            ><router-link
                                @click.native="
                                    $event.stopImmediatePropagation()
                                "
                                :to="{
                                    name: 'VvtEditProcessingActivity',
                                    params: { id: item.processingActivityId },
                                    query: { step: 1 },
                                }"
                                >{{ item.processingActivity }}</router-link
                            ></span
                        >
                        <span v-else class="font-weight-bold">{{
                            item.prev
                        }}</span>
                        <div
                            class="text-caption pt-2"
                            :class="{ 'grey--text': $vuetify.dark }"
                        >
                            {{ getDateTime(item.date) }}
                        </div>
                    </div>
                </v-card-text>
            </template>
        </div>
    </v-card>
</template>

<script>
import { dateTypes, parseISO, formatDate } from '@/utils/dateFns';

export default {
    name: 'ActivityStreamGroup',
    i18n: {
        messages: {
            en: require('@/locales/Dashboard/en.json'),
            de: require('@/locales/Dashboard/de.json'),
        },
    },
    props: {
        items: {
            type: Array,
            default: null,
        },
        groupName: {
            type: String,
            default: null,
        },
    },
    methods: {
        getDateTime(date) {
            return formatDate(parseISO(date), dateTypes.fullDateTime);
        },
    },
};
</script>
